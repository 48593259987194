import React from 'react';
import QRCodeScanner from './components/QRCodeScanner';

function App() {
    return (
        <div className="App">
            <QRCodeScanner />
        </div>
    );
}

export default App;
