import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'; // Gli stili globali dell'app
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema di PrimeReact
import 'primereact/resources/primereact.min.css'; // Stili di PrimeReact
import 'primeicons/primeicons.css'; // Icone di PrimeReact

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
