// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Stili di base per il contenitore principale */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

/* Stili per eventuali pulsanti globali */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Stili per input globali */
input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.p-button-success {
    background-color: green !important;
    border-color: green !important;
}

.p-button-danger {
    background-color: red !important;
    border-color: red !important;
}

.p-button-secondary {
    background-color: grey !important;
    border-color: grey !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA,gDAAgD;AAChD;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;AACf;;AAEA,yCAAyC;AACzC;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,4BAA4B;AAC5B;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;IACI,kCAAkC;IAClC,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;IAChC,4BAA4B;AAChC;;AAEA;IACI,iCAAiC;IACjC,6BAA6B;AACjC","sourcesContent":["\n/* Stili di base per il contenitore principale */\n.App {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  padding: 20px;\n}\n\n/* Stili per eventuali pulsanti globali */\nbutton {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  margin: 10px 0;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\n/* Stili per input globali */\ninput {\n  padding: 10px;\n  margin: 10px 0;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 100%;\n  max-width: 300px;\n}\n\n.p-button-success {\n    background-color: green !important;\n    border-color: green !important;\n}\n\n.p-button-danger {\n    background-color: red !important;\n    border-color: red !important;\n}\n\n.p-button-secondary {\n    background-color: grey !important;\n    border-color: grey !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
